import * as React from "react"
import { Link } from "gatsby"

import Layout from "components/layout"
import Seo from "components/seo"

import "./style.css"

let cases_topics = require("assets/cases_topics.json");

function MedangleCASEHomepage(){
    return(
    <div className="columns is-centered" style={{ boxSizing: "border-box" }}>
    <div className="column is-12-mobile is-12-tablet is-12-desktop padding-md">
      <div className="columns is-multiline padding-md">
        <div className="column is-12-mobile is-6-tablet is-3-desktop has-background-grey padding-md">
          <p className="has-text-white is-size-2-desktop is-size-3-tablet is-size-4-mobile has-text-weight-bold">
            Endocrinology
          </p>
          <img
          src="https://medangle.com/assets/mcq-categories/endocrinology-a670223549937b823cf671ce60e48725ea0288e0e01bd9a605002485ec743c23.svg"
            data-savepage-src="/assets/mcq-categories/endocrinology-a670223549937b823cf671ce60e48725ea0288e0e01bd9a605002485ec743c23.svg"
          />
        </div>
        <div className="column is-12-mobile is-6-tablet is-3-desktop user-mcqs-subject">
          <ul>
            <Link to="/cases/tagged/semester3,endocrinology">
              <li className="gradientBorder">
                <img
                src="https://medangle.com/assets/mcq-categories/medicine-789fcf54f39062627de6a7a27b0cc78e82fdd8be2076f2a78f52689534af0ec9.svg"
                  data-savepage-src="/assets/mcq-categories/medicine-789fcf54f39062627de6a7a27b0cc78e82fdd8be2076f2a78f52689534af0ec9.svg"
                />
                <p>
                  <span className="gradientText">Endocrinology Cases</span>
                </p>
              </li>
            </Link>{" "}
          </ul>
        </div>
        <div className="column is-12-mobile is-6-tablet is-3-desktop has-background-black padding-md">
          <p className="has-text-white is-size-2-desktop is-size-3-tablet is-size-4-mobile has-text-weight-bold">
            Head &amp; Neck
          </p>
          <img
          src="https://medangle.com/assets/mcq-categories/s3-headandneck-54b01830c026ebd82aa2bb05ecae7516b9713c66dc81c8739059e0649614259f.svg"
            data-savepage-src="/assets/mcq-categories/s3-headandneck-54b01830c026ebd82aa2bb05ecae7516b9713c66dc81c8739059e0649614259f.svg"
           />
        </div>
        <div className="column is-12-mobile is-6-tablet is-3-desktop user-mcqs-subject">
          <ul>
            <Link to="/cases/tagged/semester3,head-and-neck">
              <li className="gradientBorder">
                <img
                 src="https://medangle.com/assets/mcq-categories/medicine-789fcf54f39062627de6a7a27b0cc78e82fdd8be2076f2a78f52689534af0ec9.svg"
                  data-savepage-src="/assets/mcq-categories/medicine-789fcf54f39062627de6a7a27b0cc78e82fdd8be2076f2a78f52689534af0ec9.svg"
                />
                <p>
                  <span className="gradientText">Head &amp; Neck Cases</span>
                </p>
              </li>
            </Link>{" "}
          </ul>
        </div>
        <div className="column is-12-mobile is-6-tablet is-3-desktop color-neuro-bg padding-md">
          <p className="has-text-white is-size-2-desktop is-size-3-tablet is-size-4-mobile has-text-weight-bold">
            Neuro
          </p>
          <img
           src="https://medangle.com/assets/mcq-categories/s3-neuro-861943fbd85416f62644f5e387a1769420d603517b60ab304570e0725db37da3.svg"
            data-savepage-src="/assets/mcq-categories/s3-neuro-861943fbd85416f62644f5e387a1769420d603517b60ab304570e0725db37da3.svg"
          
          />
        </div>
        <div className="column is-12-mobile is-6-tablet is-3-desktop user-mcqs-subject">
          <ul>
            <Link to="/cases/tagged/semester3,neuro">
              <li className="gradientBorder">
                <img
                 src="https://medangle.com/assets/mcq-categories/medicine-789fcf54f39062627de6a7a27b0cc78e82fdd8be2076f2a78f52689534af0ec9.svg"
                  data-savepage-src="/assets/mcq-categories/medicine-789fcf54f39062627de6a7a27b0cc78e82fdd8be2076f2a78f52689534af0ec9.svg"
                 />
                <p>
                  <span className="gradientText">Neuro Cases</span>
                </p>
              </li>
            </Link>{" "}
          </ul>
        </div>
      </div>
      <div className="columns is-multiline padding-md">
        <div className="column is-12-mobile is-6-tablet is-3-desktop color-git1-bg padding-md">
          <p className="has-text-white is-size-2-desktop is-size-3-tablet is-size-4-mobile has-text-weight-bold">
            Gastrointestinal
          </p>
          <img
           src="https://medangle.com/assets/mcq-categories/git-b90acaf0a61e2edf65e7a8f63e77a47420855ecbac4a8ec1c6a424bb1fd761f1.svg"
            data-savepage-src="/assets/mcq-categories/git-b90acaf0a61e2edf65e7a8f63e77a47420855ecbac4a8ec1c6a424bb1fd761f1.svg"
           />
        </div>
        <div className="column is-12-mobile is-6-tablet is-3-desktop user-mcqs-subject">
          <ul>
            <Link to="/cases/tagged/semester4,gastrointestinal">
              <li className="gradientBorder">
                <img
                 src="https://medangle.com/assets/mcq-categories/git-b90acaf0a61e2edf65e7a8f63e77a47420855ecbac4a8ec1c6a424bb1fd761f1.svg"
                  data-savepage-src="/assets/mcq-categories/git-b90acaf0a61e2edf65e7a8f63e77a47420855ecbac4a8ec1c6a424bb1fd761f1.svg"
                />
                <p>
                  <span className="gradientText">Gastrointestinal Cases</span>
                </p>
              </li>
            </Link>{" "}
          </ul>
        </div>
        <div className="column is-12-mobile is-6-tablet is-3-desktop color-renal-bg padding-md">
          <p className="has-text-white is-size-2-desktop is-size-3-tablet is-size-4-mobile has-text-weight-bold">
            Renal
          </p>
          <img
           src="https://medangle.com/assets/mcq-categories/renal-236b7322ba18f81867797ce9d8b585f37687556895f1b583d825d1c8873198de.svg"
            data-savepage-src="/assets/mcq-categories/renal-236b7322ba18f81867797ce9d8b585f37687556895f1b583d825d1c8873198de.svg"
           />
        </div>
        <div className="column is-12-mobile is-6-tablet is-3-desktop user-mcqs-subject">
          <ul>
            <Link to="/cases/tagged/semester4,renal">
              <li className="gradientBorder">
                <img
                 src="https://medangle.com/assets/mcq-categories/respiratory-636e036aeda62fd5cf1171fa10fb0fae289e030e16bb258d4a018c24d0da5b11.svg"
                  data-savepage-src="/assets/mcq-categories/respiratory-636e036aeda62fd5cf1171fa10fb0fae289e030e16bb258d4a018c24d0da5b11.svg"
                />
                <p>
                  <span className="gradientText">Renal Cases</span>
                </p>
              </li>
            </Link>{" "}
          </ul>
        </div>
        <div className="column is-12-mobile is-6-tablet is-3-desktop color-repro-bg padding-md">
          <p className="has-text-white is-size-2-desktop is-size-3-tablet is-size-4-mobile has-text-weight-bold">
            Reproductive
          </p>
          <img
           src="https://medangle.com/assets/mcq-categories/ovaries-b95a64963fac39278a77cf88cabf584406d9b0651ffe83ae9a3ba76b7710fd68.svg"
            data-savepage-src="/assets/mcq-categories/ovaries-b95a64963fac39278a77cf88cabf584406d9b0651ffe83ae9a3ba76b7710fd68.svg"
            />
        </div>
        <div className="column is-12-mobile is-6-tablet is-3-desktop user-mcqs-subject">
          <ul>
            <Link to="/cases/tagged/semester4,reproductive">
              <li className="gradientBorder">
                <img
                  src="https://medangle.com/assets/mcq-categories/cardiology-3dd89538f6198c51eb9bdc9cc777cd744596bfcc3752f755a4804481ea2e9652.svg"
                  data-savepage-src="/assets/mcq-categories/cardiology-3dd89538f6198c51eb9bdc9cc777cd744596bfcc3752f755a4804481ea2e9652.svg"
                />
                <p>
                  <span className="gradientText">Reproductive Cases</span>
                </p>
              </li>
            </Link>{" "}
          </ul>
        </div>
      </div>
    </div>
  </div>
  );
}

function CASES_TOPICS_LIST(props){

    return (
        <Layout>
            <Seo title="Clinical Cases" />
            <MedangleCASEHomepage/>
            <div>
                

                <Link to="/cases/blah/blah">GO TO Non Existence Case</Link>
<h1>TOPICS:</h1>
                {cases_topics.map((topic)=>( <>
                <Link to={"/cases/tagged/"+topic}>{topic}</Link>
                 <br/>
                </>))}

               
            </div>
        </Layout>
    )
}

export default CASES_TOPICS_LIST
